import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import * as echarts from 'echarts';
import { useStore } from 'vuex';
export default {
  __name: 'RateLineView',
  setup(__props, {
    expose: __expose
  }) {
    const store = useStore();
    const phonenumber = ref(store.state.user.phonenumber);
    const chartContainer = ref(null);
    const chartContainer2 = ref(null);
    const chartContainer3 = ref(null);
    const rateList = ref([{}]);
    const pushMatch = list => {
      rateList.value = list;
      var yAxis = {
        type: 'value'
      };
      if (phonenumber.value == '15705840617') {
        yAxis = {
          type: 'value',
          interval: 3,
          scale: true,
          axisLabel: {
            show: true
          },
          splitLine: {
            show: true
          },
          axisTick: {
            show: false //不显示刻度
          },
          axisLine: {
            show: false
          },
          nameTextStyle: {
            color: "#FFFFFF"
          },
          splitArea: {
            show: false
          }
        };
      }
      footBallRate(list, yAxis);
      footBallRate2(list, yAxis);
      footBallRate3(list, yAxis);
    };
    const footBallRate = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        let value3 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hadHRate.replace('%', '')));
          list[i].value = Number(list[i].hadHRate.replace('%', ''));
          value2.push(Number(list[i].hsupportRate.replace('%', '')));
          value3.push(list[i].winrate);
        }
        const chart = echarts.init(chartContainer.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hmoney + data.dmoney + data.amoney;
              var html = `${time}<br>胜：${data.hadH}   威胜：${data.win} <br>平：${data.hadD}   威平：${data.same} <br>负：${data.hadA}   威负：${data.lost} <br>总量：${total} <br>胜量：${data.hmoney} <br>胜支持率：${data.hsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['竞彩胜概率', '支持率', '威廉胜概率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '竞彩胜概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }, {
            name: '威廉胜概率',
            data: value3,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate2 = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        let value3 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hadDRate.replace('%', '')));
          list[i].value = Number(list[i].hadDRate.replace('%', ''));
          value2.push(Number(list[i].dsupportRate.replace('%', '')));
          value3.push(list[i].samerate);
        }
        const chart = echarts.init(chartContainer2.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hmoney + data.dmoney + data.amoney;
              var html = `${time}<br>胜：${data.hadH}   威胜：${data.win} <br>平：${data.hadD}   威平：${data.same} <br>负：${data.hadA}   威负：${data.lost} <br>总量：${total} <br>平量：${data.dmoney} <br>平支持率：${data.dsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['竞彩平概率', '支持率', '威廉平概率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '竞彩平概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }, {
            name: '威廉平概率',
            data: value3,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate3 = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        let value3 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hadARate.replace('%', '')));
          list[i].value = Number(list[i].hadARate.replace('%', ''));
          value2.push(Number(list[i].asupportRate.replace('%', '')));
          value3.push(list[i].lostrate);
        }
        const chart = echarts.init(chartContainer3.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hmoney + data.dmoney + data.amoney;
              var html = `${time}<br>胜：${data.hadH}   威胜：${data.win} <br>平：${data.hadD}   威平：${data.same} <br>负：${data.hadA}   威负：${data.lost} <br>总量：${total} <br>负量：${data.amoney} <br>负支持率：${data.asupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['竞彩负概率', '支持率', '威廉负概率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '竞彩负概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }, {
            name: '威廉负概率',
            data: value3,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 暴露给外部使用的函数
    __expose({
      pushMatch
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        ref_key: "chartContainer",
        ref: chartContainer,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer2",
        ref: chartContainer2,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer3",
        ref: chartContainer3,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512)], 64);
    };
  }
};