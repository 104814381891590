import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import * as echarts from 'echarts';
export default {
  __name: 'HRateLineView',
  setup(__props, {
    expose: __expose
  }) {
    const chartContainer = ref(null);
    const chartContainer2 = ref(null);
    const chartContainer3 = ref(null);
    const rateList = ref([{}]);
    const pushMatch = list => {
      rateList.value = list;
      footBallRate(list);
      footBallRate2(list);
      footBallRate3(list);
    };
    const footBallRate = async list => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadHRate.replace('%', '')));
          list[i].value = Number(list[i].hhadHRate.replace('%', ''));
          value2.push(Number(list[i].hhsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让胜量：${data.hhmoney} <br>让胜支持率：${data.hhsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让胜概率', '支持率']
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让胜概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate2 = async list => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadDRate.replace('%', '')));
          list[i].value = Number(list[i].hhadDRate.replace('%', ''));
          value2.push(Number(list[i].dhsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer2.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让平量：${data.dhmoney} <br>让平支持率：${data.dhsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让平概率', '支持率']
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让平概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate3 = async list => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadARate.replace('%', '')));
          list[i].value = Number(list[i].hhadARate.replace('%', ''));
          value2.push(Number(list[i].ahsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer3.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让负量：${data.ahmoney} <br>让负支持率：${data.ahsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让负概率', '支持率']
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让负概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 暴露给外部使用的函数
    __expose({
      pushMatch
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        ref_key: "chartContainer",
        ref: chartContainer,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer2",
        ref: chartContainer2,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer3",
        ref: chartContainer3,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512)], 64);
    };
  }
};